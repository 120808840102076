@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700,500,600|Rubik:400,500|Nunito+Sans:400,700,600");

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

body {
  background-color: #fbfbfb !important;
}

.widget-26 {
  color: #3c4142;
  font-weight: 400;
}

.widget-26 tr:first-child td {
  border: 0;
}

.widget-26 .widget-26-job-emp-img img {
  width: 35px;
  height: 20px;
  /* border-radius: 50%; */
}

.widget-26 .widget-26-job-title {
  min-width: 250px;
}

.widget-26 .widget-26-job-title a {
  font-weight: 400;
  font-size: 0.875rem;
  color: #3c4142;
  line-height: 1.5;
}

.widget-26 .widget-26-job-title a:hover {
  color: #68cbd7;
  text-decoration: none;
}

.widget-26 .widget-26-job-title .employer-name {
  margin: 0;
  line-height: 1.5;
  font-weight: 400;
  color: #3c4142;
  font-size: 0.8125rem;
  color: #3c4142;
}

.widget-26 .widget-26-job-title .employer-name:hover {
  color: #68cbd7;
  text-decoration: none;
}

.widget-26 .widget-26-job-title .time {
  font-size: 12px;
  font-weight: 400;
}

.widget-26 .widget-26-job-info {
  min-width: 100px;
  font-weight: 400;
}

.widget-26 .widget-26-job-info p {
  line-height: 1.5;
  color: #3c4142;
  font-size: 0.8125rem;
}

.widget-26 .widget-26-job-info .location {
  color: #3c4142;
}

.widget-26 .widget-26-job-salary {
  min-width: 90px;
  font-weight: 400;
  color: #3c4142;
  font-size: 0.8125rem;
}

.widget-26 .widget-26-job-category {
  padding: -0.6rem;
  display: inline-flex;
  white-space: nowrap;
  border-radius: 15px;
  width: 60px;
}

.widget-26 .widget-26-job-category .indicator {
  width: 13px;
  height: 13px;
  margin-right: 0.5rem;
  float: left;
  border-radius: 50%;
}

.widget-26 .widget-26-job-category span {
  font-size: 0.8125rem;
  color: #fff;
  font-weight: 600;
  padding-left: 8px;
}

.widget-26 .widget-26-job-starred svg {
  width: 20px;
  height: 10px;
  color: #fd8b2c;
}

.widget-26 .widget-26-job-starred svg.starred {
  fill: #fd8b2c;
}

.bg-soft-base {
  background-color: #00b2dd;
}

.bg-soft-warning {
  background-color: #616ccf;
}

.bg-soft-success {
  background-color: #fea070;
}

.bg-soft-danger {
  background-color: #fedce0;
}

.bg-soft-info {
  background-color: #d7efff;
}

.featured-images {
  position: relative;
  color: #666666;
  border: 2px solid #333333;
  padding: 0 10px 0 30px;
}

.featured-images span {
  color: #666666;
  background: white;
  padding: 0 10px;
}

.featured-images:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background: #666666;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}

#status-buttons {
  position: relative;
  /* 1 */
  display: inline-block;
  /* 2 */
}

.vertical {
  border-left: 1px solid #eaeaea;
  height: 40px;
}

#status-buttons::after {
  /* 3 */
  content: "";
  position: absolute;
  width: 100%;
  z-index: -1;
  /* 4 */
  top: 35%;
  left: 25%;
  border: 1px solid #0c0c0c;
}

#status-buttons a {
  color: black;
  display: inline-block;
  font-size: 17px;
  font-weight: normal;
  margin-right: 0;
  text-align: center;
  text-transform: uppercase;
  min-width: 150px;
  text-decoration: none;
}

#status-buttons a:hover {
  text-decoration: none;
}

#status-buttons a.active span {
  color: white;
  background: #accf5b;
  box-shadow: rgba(0, 0, 0, 0.792157) 3px 3px 3px 0;
}

#status-buttons span {
  color: white;
  background: #22bacb;
  display: block;
  height: 58px;
  margin: 0 auto 10px;
  padding-top: 20px;
  width: 60px;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.792157) 3px 3px 3px 0;
}

div.linetop {
  border-top: 1px solid #111111;
  width: 95px;
  position: absolute;
  top: 40px;
  left: 115px;
}

@media screen and (max-width: "992px") {
  h6 {
    text-align: center !important;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */

.example::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */

.example {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

body {
  background-color: #fbfbfb;
  max-width: 1440px !important;
  margin: auto !important;
}

/* cards scrolling */

.hide-scroll-bar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}

.loader {
  border-top-color: #3498db !important;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.autocomplete-wrapper {
  width: 350px;
  position: relative;
  display: inline-block;
  margin-top: 3px;
}

.autocomplete-wrapper > div {
  width: 100%;
}

.autocomplete-wrapper input {
  /* border: 1px solid #cecece; */
  margin-top: 4px;
  padding: 1px 0px;
  background-color: transparent;
  font-size: 14px;
  width: 100%;
}

.autocomplete-wrapper input:focus {
  border-color: #0f67ff;
  box-shadow: none;
  outline: none;
}

.autocomplete-wrapper .dropdown {
  width: 140%;
  padding: 0;
  text-align: left;
  max-height: 280px;
  overflow: hidden;
  overflow-y: auto;
  background-color: #ffffff;
  border: 2px solid #3a3a3a;
  border-top: none;
}

.autocomplete-wrapper .item {
  display: block;
  cursor: pointer;
  font-size: 14px;
  padding: 10px;
}

.autocomplete-wrapper .item.selected-item {
  background-color: #0069ff;
  color: #fafbfc;
}

.autocomplete-wrapper .item:hover {
  background-color: #0069ff;
  color: #fafbfc;
}

.profileRound {
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.curser {
  cursor: pointer;
}

button[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

.hieght-18 {
  font-size: 18px;
}

.menuitem {
  cursor: pointer;
}

.font-16 {
  font-size: 16px;
}

.font-14 {
  font-size: 14px;
}

.iframe_full {
  width: 100%;
  height: 77vh;
}

.overflow-div {
  overflow: auto;
}

.custom-width .dropdown {
  width: 100% !important;
}

input {
  outline: none !important;
}

select {
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
    no-repeat 95% 50%;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-position-x: right;
  background-position-y: center;
  padding-right: 20px !important;
  /* background-origin: content-box; */
  appearance: none;
  outline: none !important;
}

.custom-scroll::-webkit-scrollbar {
  -webkit-appearance: none;
}

.custom-scroll::-webkit-scrollbar:vertical {
  width: 11px;
}

.custom-scroll::-webkit-scrollbar:horizontal {
  height: 11px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.5);
}

.errorBox {
  border: 1px solid red;
  width: 100%;
}

.hr-line {
  border-top-width: 4px !important;
  /* border-color: #077bd2 !important; */
  /* border-color: #3cba84 !important; */
  /* border-color: #00b2dd !important; */
  border-color: #005d8d !important;
  margin: 20px 0 !important;
}
.img-bg {
  width: 50px;
  height: 50px;
  background: #cff1ff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bl-1 {
  border-left: 2px solid #d8d8d8;
  margin-right: 25px;
}

.br-1 {
  border-right: 1px solid #d8d8d8;
}

.bb-1 {
  border-bottom: 1px solid #d8d8d8;
}

.b-0 {
  border: 0 !important;
}

.manual-border {
  border: 1px solid #d8d8d8;
  border-radius: 5px;
}

.formbold-file-list {
  border-radius: 6px;
  background: #f5f7fb;
  padding: 10px 20px;
  width: 100%;
}

.formbold-file-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.formbold-file-item button {
  color: #07074d;
  border: none;
  background: transparent;
  cursor: pointer;
}

.formbold-file-name {
  font-weight: 500;
  font-size: 12px;
  color: #07074d;
  padding-right: 12px;
}

.filter-3 {
  filter: brightness(0.3);
}

.lungaudio-auto {
  height: 29vh;
}

.filterinvent-1 {
  filter: invert(0.4);
}

.mauto-0 {
  margin: 0 auto;
}

#spinnerload {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@media (min-width: 260px) and (max-width: 767px) {
  .resp-block {
    display: block !important;
  }
  .resp-w-full {
    width: 100% !important;
  }
}
.heading-font {
  color: #4a4a4a;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
  margin-top: 15px;
  position: relative;
  width: 100%;
}

.heading-font::before {
  content: "";
  position: absolute;
  border-bottom: 2px solid #09729d;
  width: 44px;
  bottom: -2px;
}

.maxwidth-panel.p-multiselect {
  max-width: 350px !important;
}

.newmulti-select {
  border: 1px solid #e2e2e2 !important;
  border-radius: 5px !important;
  max-width: 100% !important;
  height: 45px;
}

.newauto-search {
  height: 45px;
  width: 100%;
}

.newauto-search input {
  border: 1px solid #e2e2e2 !important;
  border-radius: 5px !important;
  height: 45px;
  padding: 10px;
}

.newtextarea,
.newdate {
  border: 1px solid #e2e2e2 !important;
  border-radius: 5px !important;
  padding: 10px;
  width: 100%;
}

.newtable input {
  width: 100%;
  border: 1px solid #e2e2e2 !important;
  border-radius: 5px !important;
}

.newtable th {
  color: #262626;
  font-weight: 400;
  border-bottom: 1px solid #e9e9e9;
  padding: 5px 0;
}

.newtable td {
  padding: 5px;
}

.newtable-list {
  width: 25%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.newtable-list ul {
  margin-bottom: 10px;
}

.newtable-list li {
  padding: 7px 0;
}

body {
  margin: 0;
}
.join-room {
  max-width: 380px;
  margin: 0 auto;
  border-radius: 4px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
  padding: 15px;
}
.join-room .logo {
  width: 70px;
}
.join-room input {
  box-sizing: border-box;
  margin: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714;
  list-style: none;
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  background-color: #fff;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 6px;
  transition: all 0.2s;
  margin-top: 15px;
}
.join-room .join-channel {
  color: #fff;
  background-color: #1677ff;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
  font-size: 14px;
  height: 32px;
  padding: 4px 15px;
  border-radius: 6px;
  outline: none;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid rgba(0, 0, 0, 0);
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  touch-action: manipulation;
  line-height: 1.5714285714;
  box-sizing: border-box;
  overflow: visible;
  margin-top: 10px;
}
.join-room .join-channel.disabled {
  background-color: #d9d9d9;
  box-shadow: none;
}
.room {
  padding-top: 10px;
}
.user-list {
  padding-top: 1rem;
  padding: 2.5rem;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  flex: 1 1 0%;
  display: flex;
}
.user {
  /* border-style: solid; */
  /* --un-border-opacity: 1; */
  /* border-color: rgba(75, 85, 99, var(--un-border-opacity)); */
  /* border-width: 1px; */
  /* box-sizing: border-box; */
  width: "100%";
  min-height: 216px;
}
.user-name {
  --un-text-opacity: 1;
  color: rgba(255, 255, 255, var(--un-text-opacity));
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  --un-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--un-bg-opacity));
  grid-gap: 0.25rem;
  gap: 0.25rem;
  align-items: center;
  display: inline-flex;
  z-index: 2;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
}

@media (min-width: 768px) {
  .video-grid {
      
  }
}

.control {
  --un-text-opacity: 1;
  color: rgba(209, 213, 219, var(--un-text-opacity));
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  --un-bg-opacity: 1;
  background-color: rgba(33, 36, 44, var(--un-bg-opacity));
  grid-gap: 0.75rem;
  gap: 0.75rem;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  bottom: 0px;
  width: 100%;
  box-sizing: border-box;
}
.left-control {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  align-items: center;
  flex: 1 1 0%;
  display: flex;
  height: 100%;
}
.i-microphone {
  --un-icon: url("data:image/svg+xml;utf8,%3Csvg viewBox='0 0 24 24' width='1.2em' height='1.2em' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath fill='currentColor' d='M12 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3m7 9c0 3.53-2.61 6.44-6 6.93V21h-2v-3.07c-3.39-.49-6-3.4-6-6.93h2a5 5 0 0 0 5 5a5 5 0 0 0 5-5h2Z'/%3E%3C/svg%3E");
  -webkit-mask: var(--un-icon) no-repeat;
  mask: var(--un-icon) no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  background-color: currentColor;
  color: inherit;
  width: 1.2em;
  height: 1.2em;
  font-size: 1.2rem;
}
.i-microphone.off {
  opacity: 0.2;
}
.i-camera {
  --un-icon: url("data:image/svg+xml;utf8,%3Csvg viewBox='0 0 24 24' width='1.2em' height='1.2em' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath fill='currentColor' d='M4 4h3l2-2h6l2 2h3a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2m8 3a5 5 0 0 0-5 5a5 5 0 0 0 5 5a5 5 0 0 0 5-5a5 5 0 0 0-5-5m0 2a3 3 0 0 1 3 3a3 3 0 0 1-3 3a3 3 0 0 1-3-3a3 3 0 0 1 3-3Z'/%3E%3C/svg%3E");
  -webkit-mask: var(--un-icon) no-repeat;
  mask: var(--un-icon) no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  background-color: currentColor;
  color: inherit;
  width: 1.2em;
  height: 1.2em;
  font-size: 1.2rem;
}
.i-camera.off {
  opacity: 0.2;
}
.btn {
  display: inline-flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  align-items: center;
  font-size: 0;
  color: inherit;
}
.btn-phone {
  border-radius: 0.55rem;
  padding: 0.5rem 4rem;
  color: #fff;
  background-color: #16a34a;
}
.btn-phone-active {
  background-color: #dc2626;
}
.btn .btn-phone {
  border-radius: 0.55rem;
  padding: 0.5rem 4rem;
  color: #fff;
  background-color: #16a34a;
}
.btn .btn-phone-active {
  background-color: #dc2626;
}
.i-phone-hangup {
  --un-icon: url("data:image/svg+xml;utf8,%3Csvg viewBox='0 0 24 24' width='1em' height='1em' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath fill='currentColor' d='M12 9c-1.6 0-3.15.25-4.6.72v3.1c0 .4-.23.74-.56.9c-.98.49-1.87 1.12-2.67 1.85c-.17.18-.42.29-.67.29c-.3 0-.55-.12-.73-.3L.29 13.08a.99.99 0 0 1-.29-.7c0-.28.11-.53.29-.71C3.34 8.77 7.46 7 12 7c4.54 0 8.66 1.77 11.71 4.67c.18.18.29.43.29.71c0 .27-.11.52-.29.7l-2.48 2.48c-.18.18-.43.3-.73.3a.98.98 0 0 1-.68-.29c-.79-.73-1.68-1.36-2.66-1.85a.996.996 0 0 1-.56-.9v-3.1C15.15 9.25 13.6 9 12 9Z'/%3E%3C/svg%3E");
  -webkit-mask: var(--un-icon) no-repeat;
  mask: var(--un-icon) no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  background-color: currentColor;
  color: inherit;
  width: 1em;
  height: 1em;
  font-size: 1.2rem;
}
.i-phone {
  --un-icon: url("data:image/svg+xml;utf8,%3Csvg viewBox='0 0 24 24' width='1em' height='1em' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath fill='currentColor' d='M6.62 10.79c1.44 2.83 3.76 5.15 6.59 6.59l2.2-2.2c.28-.28.67-.36 1.02-.25c1.12.37 2.32.57 3.57.57a1 1 0 0 1 1 1V20a1 1 0 0 1-1 1A17 17 0 0 1 3 4a1 1 0 0 1 1-1h3.5a1 1 0 0 1 1 1c0 1.25.2 2.45.57 3.57c.11.35.03.74-.25 1.02l-2.2 2.2Z'/%3E%3C/svg%3E");
  -webkit-mask: var(--un-icon) no-repeat;
  mask: var(--un-icon) no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  background-color: currentColor;
  color: inherit;
  width: 1em;
  height: 1em;
  font-size: 1.2rem;
}