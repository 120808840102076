.p-multiselect {
    border: none;
    border-bottom: 2px solid #d1d5db;
    border-radius: unset;
    max-width: 250px;
    white-space: nowrap;
}

.p-multiselect-panel {
    max-width: 367px;
}

.p-multiselect-filter {
    padding: 5px;
}

.p-multiselect-items {
    width: max-content;
}

.p-multiselect-item {
    padding: 2px;
    font-size: 14px;
    

}

.p-placeholder {
    opacity: .7;
    padding: 6px;
    font-size: 14px;
}

/* selectbox */

.p-dropdown-label {
    padding: 5px 5px;
}

.p-dropdown {
    border: none;
    border-bottom: 1px solid #d1d5db;
    border-radius: 0;
}

.p-dialog-header,.p-dialog-footer,.p-dialog-content {
    padding: 5px 20px 5px 20px !important;
}

.p-inputtext {
    height: 35px !important;
}