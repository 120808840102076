.chat-loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}
 
.dot1, .dot2, .dot3 {
  width: 12px;
  height: 12px;
  background-color: #3498db; /* You can customize the color */
  border-radius: 50%;
  margin: 0 5px;
  animation: bounce 0.6s infinite alternate;
}
 
.dot2 {
  animation-delay: 0.2s;
}
 
.dot3 {
  animation-delay: 0.4s;
}
 
@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-15px);
  }
}