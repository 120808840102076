@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
}

.floating-icon {
  animation: float 3s ease-in-out infinite;
  position: fixed; 
  bottom: 20px; 
  right: 20px;
  z-index: 1000; 
  background-color: rgba(0, 93, 141);
  color: white; 
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); 
  border-radius: 10px;
}
.pr_id_3_header{
  margin-top: 18px;
}
.p-inputtextarea, 
.p-inputtext, 
.p-component, 
.p-filled {
  padding: 8px;
}
.arrowicon{    height: 29px;
    width: 30px;
    padding: 5px 6px;
    border-radius: 23px;}
    .bgclr{background-color:#F5FAFF;}
    .borderstyle{    border: 1px solid #7dbeff;height: 37px;
      padding: 6px 8px;
      border-radius: 18px;}
      .borderstyle2{ border: 1px solid #7dbeff;height: 37px;
        padding: 7px 8px;
        border-radius: 18px;}
        .btnborder{border:2px solid #004171;padding: 7px 27px;border-radius: 4px;}