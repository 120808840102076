@media screen and (max-width: 991px) {
    .customized-timeline .p-timeline-event:nth-child(even) {
        flex-direction: row !important;
    }

    .customized-timeline .p-timeline-event:nth-child(even) .p-timeline-event-content {
        text-align: left !important;
    }

    .customized-timeline .p-timeline-event-opposite {
        flex: 0.1 !important;
    }

    .customized-timeline .p-card {
        margin-top: 1rem;
        width: 40%;
    }
}

.customized-timeline .p-timeline-event-opposite {
    flex: 0.1 !important;
}

.customized-timeline .p-component {
    box-sizing: border-box;
    width: 20%;
}