.loader-overlay {
  position: absolute;
  height: 100%;
  background-color: #eafeff9e;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  opacity: .5;
}
.loader, .loader:before, .loader:after {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
  z-index: 99999;
}
.loader {
  color: #1a5783;
  font-size: 10px;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
 

/* .loader {
  border-top-color: #1a5783 !important;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
} */

.loader:before, .loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%, 80%, 100% {
    box-shadow: 0 2.5em 0 -1.3em;
 }
  40% {
    box-shadow: 0 2.5em 0 0;
 }
}
@keyframes load7 {
  0%, 80%, 100% {
    box-shadow: 0 2.5em 0 -1.3em;
 }
  40% {
    box-shadow: 0 2.5em 0 0;
 }
}
.react-loader-overlay {
  border-top-color: #3498db !important;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
  width: 50px;
  height: 50px;
}
.react-loader {
  border-top-color: #3498db !important;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
  width: 50px;
  height: 50px;
}
@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
 }
  100% {
    -webkit-transform: rotate(360deg);
 }
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
 }
  100% {
    transform: rotate(360deg);
 }
}
